import "lazysizes";
import "picturefill";
// var $ = require('jQuery');

//アコーディオン
$(".js-menu__item__link").each(function () {
  $(this).on("click", function () {
    $("+.system__box,+.system__box--s,+.que__box", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

//ヘッダーメニュー
$("#header__spBtn, .header__spMenu__item").on("click", function () {
  $("html").toggleClass("scroll-prevent");
  if ($("#header__spBtn").hasClass("is-active")) {
    $("#header__spBtn").removeClass("is-active");
    $("#header__spMenu").removeClass("is-active");
  } else {
    $("#header__spBtn").addClass("is-active");
    $("#header__spMenu").addClass("is-active");
  }
});

//アコーディオン
$(".header__spMenu__accordion").each(function () {
  $(this).on("click", function () {
    $("+.header__spMenu__accordion__list", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

// プライバシー
$(function () {
  $(".wpcf7-list-item-label").replaceWith(function () {
    $(this).replaceWith(
      `<span></span><p><a class="wpcf7-list-item-label" href="${pathUrl}/privacy" target="_blank">` +
        $(this).text() +
        `<img src="${pathSrc}/img/common/external-link.svg" alt=""/></a>について同意する`
    );
  });
});
